import React, { Component } from "react";
import { connect } from 'react-redux';
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import Wrapper from "../Wrapper/Wrapper";
import Aux from "../Wrapper/Auxiliary";
import CompanyInfo from "./CompanyInfo";
import ClientInfo from "./ClientInfo";
import Team from "./Team";
import Testimonial from "./Testimonials";
import usersImg from "./Assets/users.png";
import codeImg from "./Assets/code.png";
import briefcaseImg from "./Assets/briefcase.png";
import coffeeImg from "./Assets/coffee.png";
import slide1Img from "./Assets/slider/slide-1.png";
import slide2Img from "./Assets/slider/slide-2.png";
import slide3Img from "./Assets/slider/slide-3.png";
import team1Img from "./Assets/teams/joe.jpg";
import team2Img from "./Assets/teams/rajiv.jpg";
import tImg1 from "./Assets/testimonial-1.jpg";
import tImg2 from "./Assets/testimonial-2.jpg";
import tImg3 from "./Assets/testimonial-3.jpg";
import store from "../../Redux/Store"

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerTitle: 'Who We Are',
      headerDesc: 'Who We Are',
      clientInfo: {
        projectInfo: [
          { imgSrc: usersImg, title: '3456', description: 'CLIENTS SERVED' },
          { imgSrc: codeImg, title: '6456', description: 'LINES OF CODE' },
          { imgSrc: briefcaseImg, title: '423', description: 'NO.OF PROJECTS' },
          { imgSrc: coffeeImg, title: '2323', description: 'CUPS OF COFFEE' }
        ],
        callInfo:{
          phone:'+919999999999',
          email:'support@parkengage.com'
        },
        carouselInfo:[
          { imgSrc: slide1Img },
          { imgSrc: slide2Img },
          { imgSrc: slide3Img }
        ]
      },
      teamInfo:{
        teamMembers:[
          { imgSrc: team2Img, name: 'Rajiv Jain', position:'Founder and CEO, ParkEngage', description: "Rajiv Jain is the Founder and Chief Executive Officer of ParkEngage, a leading digital cloud platform revolutionizing the parking industry with its mantra, 'Digital at Every Touchpoint.' Under his leadership, ParkEngage offers a comprehensive suite of SaaS solutions—including Touchless Self-Parking, Valet, Permits, Monthlies, and Reservations—designed to digitize and enhance every facet of parking operations.<br/> <br/> Before establishing ParkEngage in 2020, Rajiv amassed extensive experience in technology and digital transformation across various industries. His career began as a Software Engineer at Fujitsu Siemens Computers in 1989, followed by a role as an Engineering Manager at Sun Microsystems in 1997. In 2000, he served as Director of Product Management at Asera, Inc. Rajiv's diverse background in software engineering and product management has been instrumental in developing enterprise-class commercial technology products.<br/><br/>Rajiv holds a Bachelor of Technology degree from the Indian Institute of Technology, Kanpur, an M.S. in Computer Science from Bowling Green State University, and an MBA from the University of California, Berkeley, Haas School of Business. His commitment to innovation and excellence continues to drive ParkEngage's mission to provide cutting-edge digital solutions for the parking industry. <br/><br/>" },
          { imgSrc: team1Img, name: 'Joe Parker', position:'Vice President of Customer Success, ParkEngage', description: "Joe Parker serves as the Vice President of Customer Success at ParkEngage, bringing a wealth of experience and a deep understanding of the parking and mobility industry. In his role, Joe is dedicated to ensuring that clients achieve optimal outcomes through the implementation of ParkEngage's innovative solutions.<br/>With a career spanning over two decades, Joe has held various leadership positions that have honed his expertise in customer relations, business intelligence, and operational efficiency. His insights into industry trends and data-driven decision-making have been instrumental in guiding organizations toward enhanced performance and growth. Joe is a recognized thought leader in the parking industry, contributing to publications such as Parking & Mobility Magazine. His article, 'Data Driving Decisions,' co-authored with Rajiv Jain, delves into the significance of business intelligence and key performance indicators for predictive digital parking operations.<br/><br/>In addition to his written contributions, Joe has shared his expertise through presentations at industry conferences. Notably, he led a session titled 'Business Intelligence and KPIs for Parking Operations' at the National Parking Association Convention and Expo, where he discussed the application of data analysis in enhancing parking operations. Joe's commitment to advancing the parking industry is further demonstrated through his active participation in professional organizations. He is involved with the International Parking & Mobility Institute, contributing to discussions that shape the future of parking and mobility. <br/> <br/>At ParkEngage, Joe continues to drive customer success by leveraging his extensive experience and passion for innovation, ensuring that clients are well-equipped to navigate the evolving landscape of the parking industry." },

          
        
        ]
      },
      testimonials:{
        items:[
          {
            src: tImg1,
            name: 'Mike Jone',
            caption: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus quam ipsum vel deserunt harum, reprehenderit maiores magni doloribus nesciunt'
          },
          {
            src: tImg2,
            name: 'Mike Jone',
            caption: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus quam ipsum vel deserunt harum, reprehenderit maiores magni doloribus nesciunt'
          },
          {
            src: tImg3,
            name: 'Mike Jone',
            caption: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus quam ipsum vel deserunt harum, reprehenderit maiores magni doloribus nesciunt'
          }
        ]
      }
    }
  }

  render() {
    return (<Aux>
      <BreadCrumb headerTitle={this.state.headerTitle} headerName={this.state.headerTitle} />
      <CompanyInfo />
      <ClientInfo clientInfo={this.state.clientInfo} />
      <Team teamInfo={this.state.teamInfo} />
      {/* <Testimonial testimonial={this.state.testimonials} />  */}
    </Aux>)
  }
}

const mapStateToProps = state => {
  return {
    isFetching:state.cont.isFetching,
    result:state.cont.result
  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper(AboutUs, "About-us"));