import React from "react";
export const getWebUrl = (user_type,access_token,refresh_token,customerUrl,adminUrl, partnerSlug) => {
    switch (user_type) {
        case '5':
            return `${customerUrl}navigating/${access_token}/${refresh_token}/0/${partnerSlug ? partnerSlug : 'parkengage'}`
            break;
        default:
            return `${adminUrl}navigating/${access_token}/${refresh_token}/${user_type}`
            break;
    }
}

export const restrictInValidCharPassword = (value = '') => {
    // List of restricted characters
    // const restrictedChars = /[()\\`~:;"/<>]/g
    const restrictedChars = /[^!@#$%^&*?\w\s-]/g
  
    // Remove restricted characters from the input value
    let filteredValue = value?.replace(restrictedChars, '')
    filteredValue = filteredValue?.replace('_', '')
    return filteredValue
  }

 export function convertToParagraph(text) {
    return <p className="grey-text">
    {text.split('<br/>').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ))}
</p>
}