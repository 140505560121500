import React from "react";
import { convertToParagraph } from "../../login/helper";

const Team = (props) => {
 
  return (
    <section className="pb-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <h2 className="font-weight-bold text-center text-dark pt-5 pb-1">OUR THINKTANK</h2>
            <p className="font-weight-light text-center pb-3 mb-4">Innovative ideas, creative solutions, and strategic insights shaping the future.</p>
            </div>
        </div>
    
          {props.teamInfo.teamMembers.map((team, i) => {
            return (
              <div key={i} className="row  ">
                <div className="avatar col-sm-3 col-12 mb-3 ">
                  <img src={team?.imgSrc} className="rounded z-depth-1 img-fluid" alt="Sample avatar" />
                </div>
                <div className="col-sm-9 col-12 mb-3 ">
                  <h4 className="font-weight-bold mb-3">{team?.name}</h4>
          
                  <h6 className="font-weight-bold grey-text mb-3">{team?.position}</h6>
                  {convertToParagraph(team?.description)}
                </div>
              </div>
            )
          })}
 
      </div>
    </section>
  )
}

export default Team;